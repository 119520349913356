import React, { createContext, type ReactNode, useContext, useMemo, useRef } from 'react'
import links from 'links'
import { Redirect, useHistory, useSearchParams } from 'router'
import { processError, sanitizeRedirectUrl } from 'helpers'
import { useUser } from 'modules/user'
import { useQuizResult } from 'modules/recommendations/quiz'


type Flow = Analytics.RegisterFlow

type AuthPageContextValue = {
  flow: Flow
  isFlow: boolean
  redirectUrl: string
  isExpired: boolean
  onRedirect: () => Promise<void>
}

const AuthPageContext = createContext<AuthPageContextValue>(null)

export const useAuthPage = () => useContext(AuthPageContext)

const getFlowByRedirectUrl = (url: string): Flow => {
  if (!url) {
    return 'Default'
  }

  if (url.startsWith(links.subscribe)) {
    return 'Subscription'
  }

  if (url.startsWith(links.giftSubscriptionPayment)) {
    return 'Gift'
  }

  if (url.startsWith(links.giftCardPayment)) {
    return 'Gift card'
  }

  if (url.startsWith(links.checkout)) {
    return 'Payment'
  }

  if (url.startsWith(links.scentProfile)) {
    return 'Quiz'
  }

  return 'Default'
}

/**
 * This provider helps with redirects logic for auth pages
 */
type AuthPageProviderProps = {
  children: ReactNode
  withRedirect?: boolean
}
const AuthPageProvider: React.FC<AuthPageProviderProps> = (props) => {
  const { children, withRedirect = true } = props
  const [ { redirect: customRedirectUrl, expired: isExpired } ] = useSearchParams()
  const history = useHistory()
  const isRenderedRef = useRef(false)

  const { isLoggedIn, isFetching } = useUser()
  const { isValid: hasValidQuizParams, sendResult: sendQuizResult } = useQuizResult()

  const redirectUrl = sanitizeRedirectUrl(customRedirectUrl) || links.subscription.main
  const onRedirectRef = useRef(null)

  // ATTN it is required because component will be re-rendered after cache update with old onRedirect callback
  onRedirectRef.current = async () => {
    try {
      if (hasValidQuizParams) {
        await sendQuizResult()
      }
    }
    catch (error) {
      processError(error)
    }

    history.push(redirectUrl, {
      searchParams: {
        redirect: null,
      },
    })
  }

  const value = useMemo(() => {
    // if redirect param is set to 'Quiz' we treat it as flow
    const flow = getFlowByRedirectUrl(customRedirectUrl)
    const isFlow = flow === 'Quiz'

    return {
      isFlow,
      flow,
      redirectUrl,
      isExpired: Boolean(isExpired), // token is expired
      onRedirect: async () => {
        await onRedirectRef.current()
      },
    }
  }, [ customRedirectUrl, redirectUrl, isExpired ])

  // don't hide page if it was rendered before during loading
  if (!isRenderedRef.current) {
    if (isFetching) {
      return null
    }

    if (isLoggedIn && withRedirect) {
      return (
        <Redirect to={redirectUrl} withSearch searchParams={{ redirect: null, magicToken: null }} />
      )
    }
  }

  isRenderedRef.current = true

  return (
    <AuthPageContext.Provider value={value}>
      {children}
    </AuthPageContext.Provider>
  )
}


export default AuthPageProvider
