import React from 'react'
import links from 'links'
import { useSearch, useSearchParams } from 'router'
import { track } from 'analytics'
import { useSSR } from 'hooks'

import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { Button, Form } from 'components/inputs'
import EmailInput from 'compositions/inputs/EmailInput/EmailInput'

import AuthPageContainer from '../components/AuthPageContainer/AuthPageContainer'
import SocialButtons from '../components/SocialButtons/SocialButtons'

import useLoginPage from './util/useLoginPage'

import messages from './messages'


const LoginPage: React.FunctionComponent = () => {
  const { form, isFlow, isSubmitting, handleLoginClick, isExpired } = useLoginPage()
  const [ { exists } ] = useSearchParams()
  const search = useSearch()
  const isSSR = useSSR()

  const title = isExpired ? messages.expiredTitle : (
    isFlow ? messages.flowTitle : messages.title
  )

  return (
    <AuthPageContainer>
      <Text
        message={title}
        style="sh3"
        tag="h1"
        html
      />
      {
        Boolean(exists) && (
          <Text
            className="mt-24"
            style="p3"
            message={messages.emailExists}
          />
        )
      }
      <Form
        className="mt-24"
        loading={isSubmitting}
        onSubmit={handleLoginClick}
      >
        <EmailInput
          className="mt-12"
          type="email"
          size={48}
          label={messages.email}
          field={form.fields.email}
          autoComplete="username"
          data-testid="email"
        />
        <Text className="mt-16" style="p2" message={messages.magicLinkHint} />
        <Button
          className="mt-32"
          size={56}
          fullWidth
          title={messages.magicLinkButtonTitle}
          disabled={isSSR}
          loading={isSubmitting}
          type="submit"
          data-testid="loginButton"
        />
        <div className="mt-24 flex justify-center">
          <Href
            className="link-gold-50 text-p4 underline"
            to={`${links.register}${search}`}
            message={messages.noAccount}
            onClick={() => {
              track('Create account link click')
            }}
            data-testid="noAccountLink"
          />
        </div>
      </Form>
      <SocialButtons className="mt-40" />
    </AuthPageContainer>
  )
}


export default LoginPage
