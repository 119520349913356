import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'router'
import logger from 'logger'
import { useForm } from 'formular'
import localStorage from 'local-storage'
import { track } from 'analytics'
import links from 'links'
import { openModal } from 'modals'
import { constants, processError } from 'helpers'
import { email, required } from 'helpers/validators'
import { useMagicLinkCreate } from 'modules/user'
import { useGiftSubscription } from 'modules/gifts'
import { Text } from 'components/dataDisplay'

import { useAuthPage } from 'containers/AuthPageProvider/AuthPageProvider'

import messages from '../messages'


const useLoginPage = () => {
  const { giftSubscription } = useGiftSubscription()
  const history = useHistory()

  const form = useForm<LoginPage.Form>({
    fields: {
      email: [ required, email ],
    },
    initialValues: {
      email: '',
    },
  })

  useEffect(() => {
    const email = giftSubscription?.email || localStorage.getSessionItem(constants.localStorageNames.authEmail) || ''
    form.fields.email.set(email)
  }, [ giftSubscription, form ])

  const { flow, isFlow, isExpired, redirectUrl } = useAuthPage()
  const redirectUrlRef = useRef(redirectUrl)

  redirectUrlRef.current = redirectUrl

  useEffect(() => {
    track('Login page', {
      flow,
    })
  }, [ flow ])

  const [ submitMagicLink, { isFetching: isSubmitting } ] = useMagicLinkCreate()

  const handleLoginClick = useCallback(async () => {
    try {
      const { values: { email }, errors } = await form.submit()

      localStorage.setSessionItem(constants.localStorageNames.authEmail, email)

      if (errors) {
        const fieldErrors = Object.keys(errors).map((field) => `${field}: ${errors[field].en}`).join('. ')

        track('Login fail', {
          reason: `${fieldErrors}`,
        })

        return
      }

      track('Send login link click')

      await submitMagicLink({
        email,
        redirectUrl: redirectUrlRef.current,
      })

      openModal('commonModal', {
        title: messages.magicLinkModal.title,
        text: messages.magicLinkModal.text,
        children: (
          <div className="flex h-full flex-col justify-end">
            <Text message={messages.magicLinkModal.additionalContent} style="p2" />
          </div>
        ),
        primaryButton: {
          toTab: 'https://scentbird.zendesk.com/hc/en-us/articles/8212812478619-I-haven-t-received-the-Magic-Link-email-What-can-I-do-',
          title: messages.magicLinkModal.buttonTitle,
          fullWidthOnMobile: true,
        },
        'data-testid': 'magicLinkSentModal',
      })
    }
    catch (error) {
      if (error.cause?.__typename === 'UserLogInError') {
        const { errorCode, message } = error.cause

        if (errorCode === 'USER_SHOULD_RESET_PASSWORD') {
          logger.warn(`${errorCode} error found. We should not return this error!`)
          history.push(links.recoverPassword)
          return
        }

        track('Login fail', {
          reason: message || errorCode,
        })
      }
      else {
        track('Login fail', {
          reason: error.message,
        })
      }

      processError(error)
    }
  }, [ form, history, submitMagicLink ])

  return {
    form,
    isFlow,
    isSubmitting,
    handleLoginClick,
    isExpired,
  }
}


export default useLoginPage
