export default {
  title: {
    en: 'Log in to your account.',
  },
  flowTitle: {
    en: 'Login to your account to see<br />your results!',
  },
  expiredTitle: {
    en: 'Your link has expired,<br /> please try again',
  },
  magicLinkButtonTitle: {
    en: 'Send log in Link',
  },
  noAccount: {
    en: 'Don’t have an account?',
  },
  emailExists: {
    en: 'This email has been already registered. Please login.',
  },
  email: {
    en: 'Email',
  },
  magicLinkHint: {
    en: 'To finalize your log in, a secure link will be sent to your email address',
  },
  magicLinkModal: {
    title: {
      en: 'We’ve sent you an email that contains your<br class="onlyDesktop" /> log in link',
    },
    text: {
      en: 'Check your email to get your link and complete your log in!<br class="onlyDesktop" /> <b>Your link expires in 10 minutes.</b>',
    },
    additionalContent: {
      en: 'Need some help? Check out our FAQs on how our login link works!',
    },
    buttonTitle: {
      en: 'Login Link FAQs',
    },
  },
}
