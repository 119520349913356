import { useCallback, useEffect } from 'react'
import { processError, SilentError } from 'helpers'
import config from 'config'
import logger from 'logger'
import { useReducerState, useScriptLoader } from 'hooks'


const initAppleLogin = () => new Promise<void>((resolve, reject) => {
  try {
    window.AppleID.auth.init({
      clientId: config.appleIdClientId,
      // redirectURI is unused but required
      // it should be:
      // - added to SB Apple account configuration
      // - match current domain (including port)
      // - redirectURI and current url should be "https" (so to check locally you need to run it with https)
      // thanks to Apple that it was not included in the documentation
      redirectURI: `${window.location.origin}/apple/signin`,
      scope: 'name email',
      state: 'authorized',
      usePopup: true,
    })

    logger.info('[Apple] initialized')
    resolve()
  }
  catch (error) {
    reject(new SilentError(error, '[Apple] init error'))
  }
})

type AppleAuthResponse = {
  idToken: string
  code: string
  state: string
  email?: string
  firstName?: string
  lastName?: string
}

const appleLogin = () => new Promise<AppleAuthResponse>(async (resolve, reject) => {
  try {
    const appleUser = await window.AppleID.auth.signIn()
    const authResponse = appleUser?.authorization

    if (authResponse) {
      const { id_token, code, state } = authResponse

      const email = appleUser?.user?.email
      const firstName = appleUser?.user?.name?.firstName
      const lastName = appleUser?.user?.name?.lastName

      logger.info(`[Apple] user has logged in`)

      resolve({
        idToken: id_token,
        code,
        state,
        email,
        firstName,
        lastName,
      })
    }
    else {
      reject(new SilentError(null, '[Apple] window was closed'))
    }
  }
  catch (error) {
    reject(new SilentError(error, `[Apple] error ${JSON.stringify(error)}`))
  }
})


const useAppleAuth = () => {
  const { isScriptLoaded } = useScriptLoader('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', {
    async: true,
  })

  const [ { auth, isInitialized, isFetching }, updateState ] = useReducerState({
    auth: null,
    isInitialized: false,
    isFetching: false,
  })

  useEffect(() => {
    if (isScriptLoaded) {
      initAppleLogin().then(() => {
        updateState({
          isInitialized: true,
        })
      }, (error) => {
        processError(error)
      })
    }
  }, [ updateState, isScriptLoaded ])

  const login = useCallback(async () => {
    if (!isScriptLoaded) {
      return
    }

    try {
      updateState({
        isFetching: true,
      })

      const auth = await appleLogin()

      updateState({
        auth,
        isFetching: false,
      })

      return auth
    }
    catch (error) {
      updateState({
        auth: null,
        isFetching: false,
      })

      throw error
    }
  }, [ isScriptLoaded, updateState ])

  return {
    auth,
    login,
    isReady: isScriptLoaded && isInitialized,
    isFetching,
  }
}


export default useAppleAuth
