import { useEffect, useRef } from 'react'
import { useScriptLoader } from 'hooks'
import { processError, SilentError } from 'helpers'
import config from 'config'


type UseGoogleISInitializeProps = {
  onSuccess: (data?: any) => void
  method?: 'initTokenClient' | 'initCodeClient' | 'initialize'
  scope?: string
}
const useGoogleISInitialize = ({ onSuccess, method = 'initTokenClient', ...props }: UseGoogleISInitializeProps ) => {
  const { isScriptLoaded, scriptLoadingError, isScriptLoading } = useScriptLoader('https://accounts.google.com/gsi/client', {
    async: true,
  })

  const clientRef = useRef<any>()

  useEffect(() => {
    if (scriptLoadingError) {
      processError(new SilentError(scriptLoadingError, '[Google] script did not loaded'))
      return
    }

    if (!isScriptLoaded) {
      return
    }

    const initData = {
      client_id: config.googleAppId,
      callback: (response) => {
        if (response.error) {
          return processError(new SilentError(response.error, '[Google] init token client error'))
        }

        onSuccess(response)
      },
      error_callback: (error) => {
        if (error.type === 'popup_closed') {
          return
        }

        processError(error, '[Google] Auth Error')
      },
      ...props,
    }

    if (method === 'initialize') {
      clientRef.current = window?.google?.accounts?.id?.initialize(initData)

      return
    }

    clientRef.current = window?.google?.accounts?.oauth2[method](initData)

  }, [ isScriptLoaded, scriptLoadingError, method, onSuccess, props ])

  return {
    isScriptLoaded,
    client: clientRef.current,
    isLoading: isScriptLoading,
    loadingError: scriptLoadingError,
  }
}

export default useGoogleISInitialize
